import { defineComponent, onBeforeMount, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import {
    IonContent,
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
    IonSkeletonText,
} from '@ionic/vue';
import {
  rocketOutline,
  rocketSharp,
  cubeOutline,
  cubeSharp,
} from 'ionicons/icons';

import firebase from 'firebase';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
    IonSkeletonText,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const selectedIndex = ref<number>(0);
    const user = ref<firebase.User | null>();

    const appPages = [
      {
        title: 'Latest release',
        url: '/home/latest-releases',
        iosIcon: rocketOutline,
        mdIcon: rocketSharp
      },
      {
        title: 'Packages',
        url: '/home/packages',
        iosIcon: cubeOutline,
        mdIcon: cubeSharp
      },
    ];

    const path = window.location.pathname;
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => path.toLowerCase().includes(page.url.toLowerCase()));
    }

    onBeforeMount(() => {
      firebase.auth().onAuthStateChanged((_user) => {
        if (!_user) {
          router.replace('/login');
        }

        user.value = _user;
      });
    });

    const logout = () => {
      firebase.auth().signOut();
    };
    const isSelected = (url: string) => url === route.path ? 'selected' : '';

    return {
      user,
      selectedIndex,
      appPages,
      rocketOutline,
      rocketSharp,
      cubeOutline,
      cubeSharp,
      logout,
      isSelected,
    }
  }
});
