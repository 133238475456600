<template>
  <IonSplitPane content-id="main-content">
    <ion-menu content-id="main-content" type="overlay">
      <ion-content>
        <ion-list id="inbox-list">
          <ion-list-header>
            <span v-if="user?.displayName">
              {{user.displayName}}
            </span>
            <ion-skeleton-text v-else animated style="width: 40%; height: 100%" />
          </ion-list-header>
          <ion-note>
            <span v-if="user?.email">
              {{user.email}}
            </span>
            <ion-skeleton-text v-else animated style="width: 70%; height: 100%" />
          </ion-note>

          <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
            <ion-item @click="selectedIndex = i"
                      router-direction="root"
                      :router-link="p.url"
                      lines="none"
                      detail="false"
                      class="hydrated"
                      :class="{ selected: selectedIndex === i }">
              <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <div class="flex-spacer" />

        <div class="logout">
          <ion-button fill="clear" expand="block" v-on:click="logout()">
            Logout
          </ion-button>
        </div>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </IonSplitPane>
</template>

<script lang="ts" src="./home.ts" />
<style scoped src="./home.css" />
